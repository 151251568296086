.inputBox {
    margin-bottom: 23px;

    & label {
        text-transform: capitalize;
    }
}

.inputLocation {
    flex-wrap: wrap;

    &>div {
        border-bottom: 0;

        & svg {
            display: none;
        }
    }

    & input {
        &:hover {
            border-bottom: 1px solid #b8bfd1;
        }
    }
}

.radioButtonRow {
    display: flex;
    gap: 50px;
}

.additionalLinkInput {
    margin-top: 20px;

    & button {
        margin-bottom: 10px;
    }
}

.pickupTime {
    margin-top: 23px;
    margin-bottom: 23px;
}

.daysCheckBox {
    display: flex;
    flex-direction: row;
    gap: 10px;

    & input[type="checkbox"] {
        width: 20px;
    }
}

.daysTitle {}

.daysValue {
    &>div {
        margin-bottom: 23px;
    }
}

.rowInputBox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .inputBox {
        width: calc(50% - 9px);
        margin-bottom: 0;
    }
}

.formRow {
    display: flex;
    justify-content: space-between;

    &>div {
        width: calc(50% - 9px);
    }
}

.joinForm {
    & h3 {
        margin: 0;
        padding: 0;

        @media (max-width:768px) {
            margin-top: 40px !important;
        }
    }

    & .descriptionBox {
        margin-bottom: 20px;

        & .descriptionHeading {
            font-size: 16px;
            color: #545454;
        }

        & .descriptionTitle {
            border: 1px solid #ddd;
            padding: 5px 10px;
            border-radius: 3px;

            & p {
                padding: 0;
                color: #6e6e6e;
            }
        }
    }
}

.addressBox {
    margin-bottom: 20px;

    & .addressHeading {
        font-size: 13px;
        color: #545454;
        margin-right: 3px;
    }

    & .addressName {
        color: #6e6e6e;
        font-size: 13px;
        font-weight: 600;
    }
}

.tutorBox {
    color: #6e6e6e;
    margin-bottom: 20px;

    & .tutorHeading {
        font-size: 13px;
        color: #545454;
        margin-right: 3px;
        font-weight: 600;
    }
}

.pickupWrapper {
    font-size: 13px;
    color: #545454;
    margin-right: 3px;
    font-weight: 600;
    margin-bottom: 16px;
}

.bottomButton {
    margin-top: 40px;
}

.pickupPointBox {
    & span {
        font-weight: var(--fontWeightSemiBold);
        line-height: 16px;
        font-size: 13px;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 3px;
        padding-bottom: 13px;
    }
}


.pickupTimeBox {
    margin: 16px 0;

    & span {
        font-weight: var(--fontWeightSemiBold);
        line-height: 16px;
        font-size: 13px;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 3px;
        padding-bottom: 13px;
    }
}