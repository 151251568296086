@import '../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: var(--TopbarMobileMenu_topMargin) 0 0 0;
}

.greeting {
  /* Font */
  composes: h1 from global;
  margin-bottom: 1px;
  margin-top: 16px;
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;

  color: var(--colorGrey300);

  /* Position component */
  width: initial;
  margin: 4px 0 24px 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.inbox {
  /* Font */
  composes: h2 from global;
  color: var(--marketplaceColor);
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
}

.navigationLink {
  /* Font */
  composes: h2 from global;
  color: var(--marketplaceColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
}
.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  composes: buttonPrimary from global;
}

.authenticationGreeting {
  /* Font */
  composes: h1 from global;

  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}
.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.currentPage {
  color: var(--colorBlack);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.spacer {
  width: 100%;
  height: 124px;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 4px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;

}
