@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: var(--blanc, #FFF);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
  overflow: hidden;
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100);
  /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  /* border-radius: var(--borderRadiusMedium); */
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  color: #222;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}

.authorInfo {
  color: #717171;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.authorBox {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .checkSchoolButton {
    & button {
      height: 32px;
      min-height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 4px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  & .ratingBox {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 12px;

    & .ratingStar {
      & svg {
        & path {
          fill: #717171;
        }
      }
    }

    & .ratingPoint {
      color: #717171;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    & .ratingUser {
      color: #717171;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}