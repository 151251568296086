@import '../../styles/customMediaQueries.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--colorWhite);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 0px;
  /* padding: 0 24px; */

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.heading {
  /* Font */
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
  color: #222;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  padding: 0;
}

.perUnit {
  /* Font */
  composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceColor);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {}
}

.author {
  composes: p from global;
  margin: 0px 24px 32px 24px;

  display: flex;
  flex-direction: row;
  align-items: center;

  @media (--viewportMedium) {
    width: 100%;
    margin: 12px 0 36px 0;
    color: var(--colorGrey700);
  }
}

.providerAvatar {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    margin-right: 10px;
  }
}

.providerNamePlain {
  display: inline;

  @media (--viewportLarge) {
    display: none;
  }
}

.providerNameLinked {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.orderHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    /* margin-bottom: 19px;
    border-bottom: 1px solid #F8F7FA;
    padding-bottom: 12px; */
  }
}

.orderTitle {
  /* Font */
  color: #4a4a4f;

  margin-top: 0;
  margin-bottom: 2px;
  font-size: 24px;
line-height: normal;
  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--colorGrey700);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.schoolOrderForm {
  z-index: 9;
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  display: flex;
  justify-content: space-between;
  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media(max-width:768px) {
    width: 100%;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.logoutOrderForm {
  z-index: 9;
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  display: flex;
  justify-content: space-between;
  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media(max-width:768px) {
    width: 100%;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 60px;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  display: flex;
  justify-content: space-between;
  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media(max-width:768px) {
    width: 100%;
  }

  @media (--viewportLarge) {
    display: none;
  }

}

.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 3px 0;

  @media (--viewportMedium) {
    padding: 4px 0;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    margin-right: unset;
    padding: 0;
  }
}

.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: unset;
}

.priceValue {
  /* Font */
  composes: h3 from global;
  /* color: var(--marketplaceColor); */

  margin-top: 0;
  margin-bottom: 0px;
  color: #222;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnitInCTA {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {}
}

.closedListingButton {
  border-left: 1px solid var(--colorGrey100);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.error {
  composes: h4 from global;
  color: var(--colorFail);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.errorSidebar {
  color: var(--colorFail);
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.orderWrapper {
  padding: 22px;
  border-radius: 5px;
  background: var(--blanc, #FFF);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);

  @media(max-width: 1220px) {
    width: 100%;
  }

  @media (max-width:768px) {
    background: transparent;
    box-shadow: none;
    padding-bottom: 130px;
  }
}

.orderMap {
  margin-top: 15px;
  padding: 22px;
  border-radius: 5px;
  background: var(--blanc, #FFF);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);

  @media(max-width: 1220px) {
    width: 300px;
    margin-top: 0;
  }
}

.nextSession {
  margin-top: 15px;
  padding: 22px;
  border-radius: 5px;
  background: var(--blanc, #FFF);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);

  @media(max-width: 1220px) {
    margin-top: 0;
  }

  & h2 {
    color: #105446;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 20px 0;
    padding: 0;
    border-bottom: 1px solid #F8F7FA;
  }
}

.sessonRow {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .headingName {
    color: #222;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  & .sessionDate {
    color: #222;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  & .labelSession {
    color: #717171;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 107%;
    padding-left: 5px;
  }

  & .iconImage {
    & svg {
      width: 12px;
      height: 14px;
    }
  }

  & .iconText {
    color: #079C16;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 107%;
  }
}

.sessionCard {
  padding: 7px 15px;
  border-radius: 5px;
  border: 1px solid var(--12-segon, #105446);
  background: #FFF;
}

.priceRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.totalPrice {
  color: #717171;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sessionListCard {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.orderGridBox {
  @media(max-width: 1220px) {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
}

.buyButton {
  border-radius: 45px;
  min-height: 34px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 110px;
}