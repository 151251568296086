.lessonQueryModal {
    & .usedLesson {

        & h3 {
            color: #105446;
        }

        & p {
            font-size: 16px;
            margin-bottom: 30px;
        }

        & .getLessonButton {
            margin: 20px 0;
        }
    }
}
.crossButton {
    text-align: right;
    padding-bottom: 25px;
    cursor:pointer;
}