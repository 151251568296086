.root {}

.select {
  color: var(--matterColorAnti);
  border: solid 1px var(--matterColorNegative);
  padding: 0px 15px;
  border-radius: 10px;
  height: 45px;
  padding-right: 40px;

  &>option {
    color: var(--matterColor);
  }

  &>option:disabled {
    color: var(--matterColorAnti);
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-color: var(--successColor);
}

.selectError {
  border-color: var(--failColor);
}

.multiSelectBox {
  &>div {
    /* background: #FFFFFF; */
    border-radius: 6px;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    /* height: 48px; */
    color: #525961;
    border-color: #d8dce6;

    &>div {
      padding: 5px 8px;
    }

    & input {
      height: auto;

      &:focus {
        box-shadow: unset;
      }
    }
  }
}

& :global(.css-tr4s17-option) {
  background-color: #105440 !important;
}

& :global(.css-10wo9uf-option) {
  &:active {
    background-color: #105440 !important;
    color: #fff;
  }
  &:hover {
    background-color: #fff;
  }
}
& :global(.css-d7l1ni-option) {
  background-color: #ffffff00 !important;
}